// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfGoogleAssistanceOrAlexaIntegration from "../../blocks/CfGoogleAssistanceOrAlexaIntegration/src/CfGoogleAssistanceOrAlexaIntegration";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import Payments from "../../blocks/Payments/src/Payments";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Videos from "../../blocks/videos/src/Videos";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import CfWhatsappIntegration52 from "../../blocks/CfWhatsappIntegration52/src/CfWhatsappIntegration52";
import MultipleStepRegistrationForm2 from "../../blocks/MultipleStepRegistrationForm2/src/MultipleStepRegistrationForm2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import CfWhatsappChatbot from "../../blocks/CfWhatsappChatbot/src/CfWhatsappChatbot";
import Notifications from "../../blocks/notifications/src/Notifications";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Groups from "../../blocks/Groups/src/Groups";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import Followers from "../../blocks/Followers/src/Followers";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import ManualTransactions2 from "../../blocks/ManualTransactions2/src/ManualTransactions2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CreateComment from "../../blocks/comments/src/CreateComment";



const routeMap = {
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
CfGoogleAssistanceOrAlexaIntegration:{
 component:CfGoogleAssistanceOrAlexaIntegration,
path:"/CfGoogleAssistanceOrAlexaIntegration"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
AccountScoreranking:{
 component:AccountScoreranking,
path:"/AccountScoreranking"},
Payments:{
 component:Payments,
path:"/Payments"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Videos:{
 component:Videos,
path:"/Videos"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
CfWhatsappIntegration52:{
 component:CfWhatsappIntegration52,
path:"/CfWhatsappIntegration52"},
MultipleStepRegistrationForm2:{
 component:MultipleStepRegistrationForm2,
path:"/MultipleStepRegistrationForm2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
CfWhatsappChatbot:{
 component:CfWhatsappChatbot,
path:"/CfWhatsappChatbot"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Groups:{
 component:Groups,
path:"/Groups"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Followers:{
 component:Followers,
path:"/Followers"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
ManualTransactions2:{
 component:ManualTransactions2,
path:"/ManualTransactions2"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},

  Home: {
component:ShoppingCartOrders,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
